/**
 * This is the main theme file for Gentu.
 * Modifying these values will affect the Material UI components
 * as well as our custom components.
 *
 * Ideally you should use existing Material UI components
 * but if you need to use these styles directly you can.
 *
 * To use these styles in your own SCSS:
 *
 * @import "../lib/theme/theme.scss";
 *
 * .mybutton {
 *   background-color: get-theme-style('palettePrimaryMain');
 *   box-shadow: get-theme-style('shadows4');
 * }
 */
// Shared variables - General
$fontFamily: (-apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif);

// Shared variables - Raw numbers for use in JS
$navBarWidth: 53;
$titleBarHeight: 54;
$mobileTitleBarHeight: 64;
$collapsiblePanelTitleHeight: 56;

// Shared variables - String numbers (e.g. "53px") for use in SCSS
$nav-bar-width: $navBarWidth * 1px;
$title-bar-height: $titleBarHeight * 1px;
$mobile-title-bar-height: $mobileTitleBarHeight * 1px;
$collapsible-panel-title-height: $collapsiblePanelTitleHeight * 1px;
$layout-main-padding: 24px;

// Non-theme colors
$brandPink: #ff4686;
$brandRed: #D82F2F;
$brandGreen: #53bac3;

$informationalBlue: #4990e2;
$informationalBlueLight: #e9f2fc;
$informationalBlueDark: #2161cf;

$success: #4caf5a;
$successLight: #eaf5eb;
$successDark: #24862e;

$warning: #ff9100;
$warningLight: #fff2e0;
$warningMild: #fadb3e;
$warningDark: #ff6200;

$moderateYellowLight: #fff9c1;
$moderateYellowDark: #b9ba00;

$lightGray: #e1e1e1;
$lightYellow: #fffced;

// Gentu
$primaryBaseColor: #119aab;
$secondaryBaseColor: #249297;
$errorBaseColor: #d32f2f;
$backgroundColor: #f5f7f8;
$borderColor: $lightGray;

$textGrey: rgba(0, 0, 0, 0.38);
$iconGrey: #888;

// Z-indices
$z-indices: (
  above-body: 1,
  overlay: 5,
  sticky-item: 6,
  patient-tabs: 7,
);

$theme: (
  paletteCommonBlack: #000,
  paletteCommonWhite: #fff,
  paletteCommonTransparent: rgba(0, 0, 0, 0),
  paletteCommonFullBlack: rgba(0, 0, 0, 1),
  paletteCommonDarkBlack: rgba(0, 0, 0, 0.87),
  paletteCommonLightBlack: rgba(0, 0, 0, 0.54),
  paletteCommonMinBlack: rgba(0, 0, 0, 0.26),
  paletteCommonFaintBlack: rgba(0, 20, 0, 0.12),
  paletteCommonFullWhite: rgba(255, 255, 255, 1),
  paletteCommonDarkWhite: rgba(255, 255, 255, 0.87),
  paletteCommonLightWhite: rgba(255, 255, 255, 0.54),
  palettePrimary50: #edF8F9,
  palettePrimary100: #d2ecf0,
  palettePrimary200: #aadce3,
  palettePrimary300: #7cc9d3,
  palettePrimary400: #44b3c1,
  palettePrimary500: $primaryBaseColor,
  palettePrimary600: #008392,
  palettePrimary700: #006a77,
  palettePrimary800: #0a5761,
  palettePrimary900: #023c44,
  palettePrimaryA100: lighten($brandGreen, 28%),
  palettePrimaryA200: lighten($brandGreen, 20%),
  palettePrimaryA400: lighten($brandGreen, 12%),
  palettePrimaryA700: $brandGreen,
  palettePrimaryLight: #EDF8F9,
  palettePrimaryMain: $primaryBaseColor,
  palettePrimaryDark: #023c44,
  paletteSecondary50:  #f2faf9,
  paletteSecondary100: #d8f0ee,
  paletteSecondary200: lighten($secondaryBaseColor, 50%),
  paletteSecondary300: lighten($secondaryBaseColor, 30%),
  paletteSecondary400: lighten($secondaryBaseColor, 15%),
  paletteSecondary500: $secondaryBaseColor,
  paletteSecondary600: darken($secondaryBaseColor, 8%),
  paletteSecondary700: darken($secondaryBaseColor, 16%),
  paletteSecondary800: darken($secondaryBaseColor, 24%),
  paletteSecondary900: darken($secondaryBaseColor, 32%),
  paletteSecondaryA100: darken(#17cfe0, 12%),
  paletteSecondaryA200: darken(#17cfe0, 20%),
  paletteSecondaryA400: darken(#17cfe0, 28%),
  paletteSecondaryA700: #17cfe0,
  paletteSecondaryA800: #DFF2F2,
  paletteSecondaryLight: lighten($secondaryBaseColor, 30%),
  paletteSecondaryMain: $secondaryBaseColor,
  paletteSecondaryDark: darken(#17cfe0, 28%),
  paletteBrandPink50: #FFF1F6,
  paletteBrandPink100: #FFDDE9,
  paletteBrandPink200: #FFBED5,
  paletteBrandPink300: #FF9ABE,
  paletteBrandPink400: #FF71A3,
  paletteBrandPink500: $brandPink,
  paletteBrandPink600: #DB3771,
  paletteBrandPink700: #B22D5B,
  paletteBrandPink800: #8E2448,
  paletteBrandPink900: #651934,
  paletteBrandPinkLight: #FFF1F6,
  paletteBrandPinkMain: $brandPink,
  paletteBrandPinkDark: #651934,
  paletteErrorLight: #fae6e6,
  paletteErrorMain: $errorBaseColor,
  paletteErrorDark: #bf1212,
  paletteGrey50: #fafafa,
  paletteGrey100: #f5f5f5,
  paletteGrey200: #eee,
  paletteGrey300: #e0e0e0,
  paletteGrey400: #bdbdbd,
  paletteGrey500: #9e9e9e,
  paletteGrey600: #757575,
  paletteGrey700: #616161,
  paletteGrey800: #424242,
  paletteGrey900: #212121,
  paletteGreyA100: #d5d5d5,
  paletteGreyA200: #aaa,
  paletteGreyA400: #303030,
  paletteGreyA700: #616161,
  paletteShadesDarkTextPrimary: rgba(255, 255, 255, 1),
  paletteShadesDarkTextSecondary: rgba(255, 255, 255, 0.7),
  paletteShadesDarkTextDisabled: rgba(255, 255, 255, 0.5),
  paletteShadesDarkTextHint: rgba(255, 255, 255, 0.5),
  paletteShadesDarkTextIcon: rgba(255, 255, 255, 0.5),
  paletteShadesDarkTextDivider: rgba(255, 255, 255, 0.15),
  paletteShadesDarkTextLightDivider: rgba(255, 255, 255, 0.075),
  paletteShadesDarkInputBottomLine: rgba(255, 255, 255, 0.7),
  paletteShadesDarkInputHelperText: rgba(255, 255, 255, 0.7),
  paletteShadesDarkInputLabelText: rgba(255, 255, 255, 0.7),
  paletteShadesDarkInputInputText: rgba(255, 255, 255, 1),
  paletteShadesDarkInputDisabled: rgba(255, 255, 255, 0.5),
  paletteShadesDarkActionActive: rgba(255, 255, 255, 1),
  paletteShadesDarkActionDisabled: rgba(255, 255, 255, 0.3),
  paletteShadesDarkBackgroundDefault: #303030,
  paletteShadesDarkBackgroundPaper: #424242,
  paletteShadesDarkBackgroundAppBar: #212121,
  paletteShadesDarkBackgroundContentFrame: #212121,
  paletteShadesLightTextPrimary: rgba(0, 0, 0, 0.87),
  paletteShadesLightTextSecondary: rgba(0, 0, 0, 0.54),
  paletteShadesLightTextDisabled: rgba(0, 0, 0, 0.38),
  paletteShadesLightTextHint: rgba(0, 0, 0, 0.38),
  paletteShadesLightTextIcon: rgba(0, 0, 0, 0.54),
  paletteShadesLightTextDivider: rgba(0, 0, 0, 0.15),
  paletteShadesLightTextLightDivider: rgba(0, 0, 0, 0.075),
  paletteShadesLightInputBottomLine: rgba(10, 10, 10, 0.42),
  paletteShadesLightInputHelperText: rgba(0, 0, 0, 0.54),
  paletteShadesLightInputLabelText: rgba(0, 0, 0, 0.54),
  paletteShadesLightInputInputText: rgba(0, 0, 0, 0.87),
  paletteShadesLightInputDisabled: rgba(0, 0, 0, 0.42),
  paletteShadesLightActionActive: rgba(0, 0, 0, 0.54),
  paletteShadesLightActionDisabled: rgba(0, 0, 0, 0.26),
  paletteShadesLightBackgroundDefault: #fafafa,
  paletteShadesLightBackgroundPaper: #fff,
  paletteShadesLightBackgroundAppBar: #f5f5f5,
  paletteShadesLightBackgroundContentFrame: #eee,
  paletteTextPrimary: rgba(0, 0, 0, 0.87),
  paletteTextSecondary: rgba(0, 0, 0, 0.54),
  paletteTextDisabled: rgba(0, 0, 0, 0.38),
  paletteTextHint: rgba(0, 0, 0, 0.38),
  paletteTextIcon: rgba(0, 0, 0, 0.38),
  paletteTextDivider: rgba(0, 0, 0, 0.15),
  paletteTextLightDivider: rgba(0, 0, 0, 0.075),
  paletteInputBottomLine: rgba(0, 0, 0, 0.42),
  paletteInputHelperText: rgba(0, 0, 0, 0.54),
  paletteInputLabelText: rgba(0, 0, 0, 0.54),
  paletteInputInputText: rgba(0, 0, 0, 0.87),
  paletteInputDisabled: rgba(0, 0, 0, 0.42),
  paletteActionActive: rgba(0, 0, 0, 0.54),
  paletteActionDisabled: rgba(0, 0, 0, 0.26),
  paletteBackgroundDefault: #f5f5f7,
  paletteBackgroundPaper: #fff,
  paletteBackgroundAppBar: #f5f5f5,
  paletteBackgroundContentFrame: #eee,
  shapeBorderRadius: 4, // override MUI shape.borderRadius[default=4]
  typographyFontFamily: $fontFamily,
  typographyFontSize: 14,
  typographyFontWeightLight: 300,
  typographyFontWeightRegular: 400,
  typographyFontWeightMedium: 500,
  typographyBody2FontSize: 0.875rem,
  typographyBody2FontWeight: 500,
  typographyBody2FontFamily: $fontFamily,
  typographyBody2LineHeight: 1.5rem,
  typographyBody2Color: rgba(0, 0, 0, 0.87),
  typographyBody1FontSize: 0.875rem,
  typographyBody1FontWeight: 400,
  typographyBody1FontFamily: $fontFamily,
  typographyBody1LineHeight: 1.46429em,
  typographyBody1Color: rgba(0, 0, 0, 0.87),
  typographyCaptionFontSize: 0.75rem,
  typographyCaptionFontWeight: 400,
  typographyCaptionFontFamily: $fontFamily,
  typographyCaptionLineHeight: 1.375em,
  typographyCaptionColor: rgba(0, 0, 0, 0.87),
  typographyButtonFontSize: 14,
  typographyButtonTextTransform: uppercase,
  typographyButtonFontWeight: 400,
  typographyButtonFontFamily: $fontFamily,

  // Old variant names (deprecated Material-UI v3.1+)
  typographyDisplay4FontSize: 7rem,
  typographyDisplay4FontWeight: 300,
  typographyDisplay4FontFamily: $fontFamily,
  typographyDisplay4LetterSpacing: -0.04em,
  typographyDisplay4LineHeight: 1.2,
  typographyDisplay4MarginLeft: -0.06em,
  typographyDisplay4Color: rgba(0, 0, 0, 0.54),
  typographyDisplay3FontSize: 3.5rem,
  typographyDisplay3FontWeight: 400,
  typographyDisplay3FontFamily: $fontFamily,
  typographyDisplay3LetterSpacing: -0.02em,
  typographyDisplay3LineHeight: 1.2,
  typographyDisplay3MarginLeft: -0.04em,
  typographyDisplay3Color: rgba(0, 0, 0, 0.54),
  typographyDisplay2FontSize: 2.8125rem,
  typographyDisplay2FontWeight: 400,
  typographyDisplay2FontFamily: $fontFamily,
  typographyDisplay2LineHeight: 1.06667em,
  typographyDisplay2MarginLeft: -0.04em,
  typographyDisplay2Color: rgba(0, 0, 0, 0.54),
  typographyDisplay1FontSize: 2.125rem,
  typographyDisplay1FontWeight: 400,
  typographyDisplay1FontFamily: $fontFamily,
  typographyDisplay1LineHeight: 1.2em,
  typographyDisplay1MarginLeft: 0em,
  typographyDisplay1Color: rgba(0, 0, 0, 0.54),
  typographyHeadlineFontSize: 1.5rem,
  typographyHeadlineFontWeight: 400,
  typographyHeadlineFontFamily: $fontFamily,
  typographyHeadlineLineHeight: 1.35417em,
  typographyHeadlineColor: rgba(0, 0, 0, 0.87),
  typographyTitleFontSize: 1.25rem,
  typographyTitleFontWeight: 500,
  typographyTitleFontFamily: $fontFamily,
  typographyTitleLineHeight: 1.5rem,
  typographyTitleColor: rgba(0, 0, 0, 0.87),
  typographySubheading2FontSize: 1rem,
  typographySubheading2FontWeight: 500,
  typographySubheading2FontFamily: $fontFamily,
  typographySubheading2LineHeight: 1.5rem,
  typographySubheading2Color: rgba(0, 0, 0, 0.87),
  typographySubheadingFontSize: 1rem,
  typographySubheadingFontWeight: 400,
  typographySubheadingFontFamily: $fontFamily,
  typographySubheadingLineHeight: 1.5em,
  typographySubheadingColor: rgba(0, 0, 0, 0.87),

  // New variant names
  typographyH1FontSize: 7rem,
  typographyH1FontWeight: 300,
  typographyH1FontFamily: $fontFamily,
  typographyH1LetterSpacing: -0.04em,
  typographyH1LineHeight: 1.2,
  typographyH1MarginLeft: -0.06em,
  typographyH1Color: rgba(0, 0, 0, 0.54),
  typographyH2FontSize: 3.5rem,
  typographyH2FontWeight: 400,
  typographyH2FontFamily: $fontFamily,
  typographyH2LetterSpacing: -0.02em,
  typographyH2LineHeight: 1.2,
  typographyH2MarginLeft: -0.04em,
  typographyH2Color: rgba(0, 0, 0, 0.54),
  typographyH3FontSize: 2.8125rem,
  typographyH3FontWeight: 400,
  typographyH3FontFamily: $fontFamily,
  typographyH3LineHeight: 1.06667em,
  typographyH3MarginLeft: -0.04em,
  typographyH3Color: rgba(0, 0, 0, 0.54),
  typographyH4FontSize: 2.125rem,
  typographyH4FontWeight: 400,
  typographyH4FontFamily: $fontFamily,
  typographyH4LineHeight: 1.2em,
  typographyH4MarginLeft: 0em,
  typographyH4Color: rgba(0, 0, 0, 0.54),
  typographyH5FontSize: 1.5rem,
  typographyH5FontWeight: 400,
  typographyH5FontFamily: $fontFamily,
  typographyH5LineHeight: 1.35417em,
  typographyH5Color: rgba(0, 0, 0, 0.87),
  typographyH6FontSize: 1.25rem,
  typographyH6FontWeight: 500,
  typographyH6FontFamily: $fontFamily,
  typographyH6LineHeight: 1.5rem,
  typographyH6Color: rgba(0, 0, 0, 0.87),
  typographySubtitle1FontSize: 1rem,
  typographySubtitle1FontWeight: 400,
  typographySubtitle1FontFamily: $fontFamily,
  typographySubtitle1LineHeight: 1.5em,
  typographySubtitle1Color: rgba(0, 0, 0, 0.87),
  typographySubtitle2FontSize: 1rem,
  typographySubtitle2FontWeight: 500,
  typographySubtitle2FontFamily: $fontFamily,
  typographySubtitle2LineHeight: 1.5rem,
  typographySubtitle2Color: rgba(0, 0, 0, 0.87),

  mixinsToolbarMinHeight: 56,
  breakpointsValuesXS: 360,
  breakpointsValuesSM: 600,
  breakpointsValuesMD: 960,
  breakpointsValuesLG: 1280,
  breakpointsValuesXL: 1920,
  shadows0: none,
  shadows1: (0 1px 3px 0 rgba(0, 0, 0, 0.2),0 1px 1px 0 rgba(0, 0, 0, 0.14),0 2px 1px -1px rgba(0, 0, 0, 0.12)),
  shadows2: (0 1px 5px 0 rgba(0, 0, 0, 0.2),0 2px 2px 0 rgba(0, 0, 0, 0.14),0 3px 1px -2px rgba(0, 0, 0, 0.12)),
  shadows3: (0 1px 8px 0 rgba(0, 0, 0, 0.2),0 3px 4px 0 rgba(0, 0, 0, 0.14),0 3px 3px -2px rgba(0, 0, 0, 0.12)),
  shadows4: (0 2px 4px -1px rgba(0, 0, 0, 0.2),0 4px 5px 0 rgba(0, 0, 0, 0.14),0 1px 10px 0 rgba(0, 0, 0, 0.12)),
  shadows5: (0 3px 5px -1px rgba(0, 0, 0, 0.2),0 5px 8px 0 rgba(0, 0, 0, 0.14),0 1px 14px 0 rgba(0, 0, 0, 0.12)),
  shadows6: (0 3px 5px -1px rgba(0, 0, 0, 0.2),0 6px 10px 0 rgba(0, 0, 0, 0.14),0 1px 18px 0 rgba(0, 0, 0, 0.12)),
  shadows7: (0 4px 5px -2px rgba(0, 0, 0, 0.2),0 7px 10px 1px rgba(0, 0, 0, 0.14),0 2px 16px 1px rgba(0, 0, 0, 0.12)),
  shadows8: (0 5px 5px -3px rgba(0, 0, 0, 0.2),0 8px 10px 1px rgba(0, 0, 0, 0.14),0 3px 14px 2px rgba(0, 0, 0, 0.12)),
  shadows9: (0 5px 6px -3px rgba(0, 0, 0, 0.2),0 9px 12px 1px rgba(0, 0, 0, 0.14),0 3px 16px 2px rgba(0, 0, 0, 0.12)),
  shadows10: (0 6px 6px -3px rgba(0, 0, 0, 0.2),0 10px 14px 1px rgba(0, 0, 0, 0.14),0 4px 18px 3px rgba(0, 0, 0, 0.12)),
  shadows11: (0 6px 7px -4px rgba(0, 0, 0, 0.2),0 11px 15px 1px rgba(0, 0, 0, 0.14),0 4px 20px 3px rgba(0, 0, 0, 0.12)),
  shadows12: (0 7px 8px -4px rgba(0, 0, 0, 0.2),0 12px 17px 2px rgba(0, 0, 0, 0.14),0 5px 22px 4px rgba(0, 0, 0, 0.12)),
  shadows13: (0 7px 8px -4px rgba(0, 0, 0, 0.2),0 13px 19px 2px rgba(0, 0, 0, 0.14),0 5px 24px 4px rgba(0, 0, 0, 0.12)),
  shadows14: (0 7px 9px -4px rgba(0, 0, 0, 0.2),0 14px 21px 2px rgba(0, 0, 0, 0.14),0 5px 26px 4px rgba(0, 0, 0, 0.12)),
  shadows15: (0 8px 9px -5px rgba(0, 0, 0, 0.2),0 15px 22px 2px rgba(0, 0, 0, 0.14),0 6px 28px 5px rgba(0, 0, 0, 0.12)),
  shadows16: (0 8px 10px -5px rgba(0, 0, 0, 0.2),0 16px 24px 2px rgba(0, 0, 0, 0.14),0 6px 30px 5px rgba(0, 0, 0, 0.12)),
  shadows17: (0 8px 11px -5px rgba(0, 0, 0, 0.2),0 17px 26px 2px rgba(0, 0, 0, 0.14),0 6px 32px 5px rgba(0, 0, 0, 0.12)),
  shadows18: (0 9px 11px -5px rgba(0, 0, 0, 0.2),0 18px 28px 2px rgba(0, 0, 0, 0.14),0 7px 34px 6px rgba(0, 0, 0, 0.12)),
  shadows19: (0 9px 12px -6px rgba(0, 0, 0, 0.2),0 19px 29px 2px rgba(0, 0, 0, 0.14),0 7px 36px 6px rgba(0, 0, 0, 0.12)),
  shadows20: (0 10px 13px -6px rgba(0, 0, 0, 0.2),0 20px 31px 3px rgba(0, 0, 0, 0.14),0 8px 38px 7px rgba(0, 0, 0, 0.12)),
  shadows21: (0 10px 13px -6px rgba(0, 0, 0, 0.2),0 21px 33px 3px rgba(0, 0, 0, 0.14),0 8px 40px 7px rgba(0, 0, 0, 0.12)),
  shadows22: (0 10px 14px -6px rgba(0, 0, 0, 0.2),0 22px 35px 3px rgba(0, 0, 0, 0.14),0 8px 42px 7px rgba(0, 0, 0, 0.12)),
  shadows23: (0 11px 14px -7px rgba(0, 0, 0, 0.2),0 23px 36px 3px rgba(0, 0, 0, 0.14),0 9px 44px 8px rgba(0, 0, 0, 0.12)),
  shadows24: (0 11px 15px -7px rgba(0, 0, 0, 0.2),0 24px 38px 3px rgba(0, 0, 0, 0.14),0 9px 46px 8px rgba(0, 0, 0, 0.12)),
  transitionsEasingEaseInOut: cubic-bezier(0.4, 0, 0.2, 1),
  transitionsEasingEaseOut: cubic-bezier(0, 0, 0.2, 1),
  transitionsEasingEaseIn: cubic-bezier(0.4, 0, 1, 1),
  transitionsEasingSharp: cubic-bezier(0.4, 0, 0.6, 1),
  transitionsDurationShortest: 150,
  transitionsDurationShorter: 200,
  transitionsDurationShort: 250,
  transitionsDurationStandard: 300,
  transitionsDurationComplex: 375,
  transitionsDurationEnteringScreen: 225,
  transitionsDurationLeavingScreen: 195,
  spacingUnitClose: 5, // use this when elements need to be close enough to be considered a single unit instead of two sepearate ones.
  spacingUnit: 8,
  zIndexMobileStepper: 900,
  zIndexMenu: 1000,
  zIndexAppBar: 1100,
  zIndexDrawerOverlay: 1200,
  zIndexPopover: 1250, // selects, datepicker, etc
  zIndexNavDrawer: 1300, // main sidebar
  zIndexDialogOverlay: 1400,
  zIndexDialog: 1500,
  zIndexLoginModal: 1501,
  zIndexLayer: 2000,
  zIndexSnackbar: 2900,
  zIndexTooltip: 3000
);

/**
 * Helper function to get theme styles
 * @param $key {string} theme property name e.g. 'paletteCommonBlack'
 * @returns CSS value
 */
@function get-theme-style($key) {
  @return map-get($theme, $key);
}

@function spacing-units($multiplier) {
  @return get-theme-style('spacingUnit') * $multiplier * 1px;
}

@function transition($transition) {
  @return get-theme-style("transitions" + $transition);
}

@function transition-duration($duration) {
  @return unquote(get-theme-style("transitionsDuration" + $duration) + "ms");
}

@function z-index($key) {
  @return map-get($z-indices, $key);
}

/**
/// [typography-styles gets base typography styles from a given key]
/// @param  {string} $key e.g. Display4, Headline, Body2
*/
@mixin typography-styles($key) {
  font-size: #{get-theme-style('typography'+ $key +'FontSize')};
  font-weight: #{get-theme-style('typography'+ $key +'FontWeight')};
  font-family: #{get-theme-style('typography'+ $key +'FontFamily')};
  letter-spacing: #{get-theme-style('typography'+ $key +'LetterSpacing')};
  line-height: #{get-theme-style('typography'+ $key +'LineHeight')};
  color: #{get-theme-style('typography'+ $key +'Color')};
}
