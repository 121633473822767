@import "../theme.scss";

:local(.root) {
  flex-grow: 0;

  :local(.devAsEnvironment) {
    background-color: grey !important;
  }

  :local(.grow) {
    flex-grow: 1;
  }

  :local(.menuButton) {
    margin-left: spacing-units(-1.5);
    margin-right: spacing-units(2);

    &:hover :local(.menuIcon) {
      &:hover {
        color: get-theme-style('paletteCommonDarkBlack');
      }
    }
  }

  :local(.contentContainer) {
    flex-grow: 1;
  }

  :local(.back) {
    left: spacing-units(-2.5);
    color: get-theme-style('paletteCommonWhite');
  }
}
