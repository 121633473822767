@import '~css-reset-and-normalize/scss/reset-and-normalize';
@import "./components/Layout/theme.scss";

body {
  margin: 0;
  font-size: 16px;
  line-height: 1.3;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    // for IE10 and 11
    table {
      table-layout: auto !important; // override table-layout:fixed since not working properly in IE
    }
  }

  textarea {
    // reset 75px from application.css, allow it to be bigger
    min-height: 75px;
    height: auto;
  }

  h1,
  h2,
  h3 {
    letter-spacing: 0.3px;
    text-transform: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button:focus {
    outline: none;
  }

  ul.bulleted-list {
    list-style: dot;
    margin-bottom: auto;
    padding-left: spacing-units(2);
    margin-left: spacing-units(2);
  }

  /*
   * the FroalaEditor css file is loaded after application.css in React, therefore we need to include
   * the style that override FrolaEditor default css code here again.
   */
  .fr-view {
    img.fr-dib {
      margin: auto;
      display: block;
      float: none;
      max-width: none;

      &.fr-fil {
        margin: 0 auto 0 0;
        left: 0;
      }

       &.fr-fir {
         margin: 0 0 0 auto;
         right: 0;
      }
    }

    img.fr-dii {
      margin: auto;
      display: inline-block;
      float: none;
      max-width: none;

      &.fr-fil {
        margin: 0 10px 0 0;
        left: 0;
        float: left;
      }

      &.fr-fir {
        margin: 0 0 0 10px;
        right: 0;
        float: right;
      }
    }
  }

  button {
    margin: inherit; // flag-react
  }

  /*
  iframe with id=webWidget is the iframe zendesk create
  we need to make sure it display under our login modal layer
  */
  iframe#webWidget {
    z-index: get-theme-style('zIndexDialog') - 1 !important;
  }
}


:local(.root) {
  // Add stuff here
  body {
    color: get-theme-style('typographyBody1Color');
  }
}
