@import "../Layout/theme.scss";

:local(.root) {
  position: relative;

  // rounded border, stopping at the inkline
  &::after {
    content: '';
    position: absolute;
    border: 1px solid get-theme-style('paletteGrey300');
    border-bottom: none;
    width: 100%;
    height: 48px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    pointer-events: none;
  }

  :local(.label) {
    left: 16px;
  }
  :local(.labelShrink) {
    top: 4px;
  }

  :local(.formControl) {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 16px !important;
    height: 32px;
  }

  :local(.input) {
    padding-bottom: 8px !important;
  }

  :local(.selectRoot) {
    margin-top: 0 !important;
  }
}

:local(.multilineRoot) {
  :local(.multilineLabel) {
    white-space: normal;
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    transform: translate(0, 1.5px) scale(1);
    line-height: 1.5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.00938em;
    max-height: 99999999px; // Prevent android chrome font-boosting
  }

  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border: 1px solid get-theme-style('paletteGrey300');
  border-bottom: none;
  min-height: 0;

  &> div {
    min-height: 0;
    padding: 5px 0;
    margin-top: 0;
    font-size: 1rem;
  }

  & textarea:not(:last-child) {
    border: none;
    padding-bottom: 0;
  }

  & textarea:last-child {
    border: none;
    padding-bottom: 0;
    padding-top: 0;
    min-height: 0;
  }
}

/** Add this to any InputAdornment elements to adjust for the new padding */
:local(.endAdornment) {
  margin-bottom: spacing-units(2);
  margin-right: spacing-units(-1);
}
